import React from 'react'
import './NotFound.css'
const NotFound = ({text}) => {
    console.log(text);
  return (
    <div className='notFound-body'>

    
    <div className='notfound-box'>
      <img src='notfound.gif' className='notfound-img'/>
      <h1 className='notFound-title'>{text}</h1>
    </div>
    </div>
  )
}

export default NotFound
