import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { ProgressSpinner } from 'primereact/progressspinner';
import './ShareDialogV2.css';
import { API_URL } from '../config';

const ShareDialog = ({ visible, onHide, fileName, existingPassword, userMobile, docId }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [shareLink, setShareLink] = useState('');
    const [linkType, setLinkType] = useState('without-password');
    const [password, setPassword] = useState(existingPassword || '');
    const [expiry, setExpiry] = useState('1 week');
    const [loading, setLoading] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Add a state for password visibility

    const toast = React.useRef(null);
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };
    const linkTypeOptions = [
        { label: 'Without Password', value: 'without-password' },
        { label: 'With Password', value: 'with-password' }
    ];

    const expiryOptions = [
        { label: '1 week', value: '1 week' },
        { label: '1 day', value: '1 day' },
        { label: '1 hour', value: '1 hour' },
        { label: '1 month', value: '1 month' },
        { label: 'Forever', value: 'Forever' }
    ];
    const generateLinkApi = async (payload) => {
        const response = await fetch(`${API_URL}/vault/records/link-generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        return {};
    };

    const generateShareLink = async () => {
        if (linkType === 'with-password' && !password) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter a password', life: 3000 });
            return;
        }
        setLoading(true);
        const payload = {
            usermobile: userMobile.substring(2),
            expiry,
            linkType,
            docId,
            share: true,
            password,
        };
        try {
            const resp = await generateLinkApi(payload);
            if (resp.url) {
                setShareLink(resp.url); // Assuming the response contains the generated link
                setShowLink(true);
                toast.current.show({ severity: 'success', summary: 'Link Generated', detail: 'Link successfully created', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to generate link', life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to generate link', life: 3000 });
            console.error('Error generating link:', error);
        } finally {
            setLoading(false);
        }

    };

    const handleSend = async () => {
        if (!mobileNumber) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter a mobile number', life: 3000 });
            return;
        }
        if (linkType === 'with-password' && !password) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter a password', life: 3000 });
            return;
        }
        const payload = {
            usermobile: userMobile.substring(2),
            expiry,
            linkType,
            docId,
            send: true,
            password,
            mobile: mobileNumber,
        };
        try {
            const resp = await generateLinkApi(payload);
            if (resp.url) {
                toast.current.show({ severity: 'success', summary: 'Link Generated', detail: 'Document Sent Successfully', life: 3000 });
            } else {
                console.log('ressssss', resp);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Sending Failed', life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Sending Failed', life: 3000 });
            console.error('Error sending link:', error);
        } finally {
            setLoading(false);
            if (existingPassword !== password) {
                
                // window.location.reload();
            }
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            toast.current.show({ severity: 'info', summary: 'Link Copied', detail: 'Link copied to clipboard!', life: 2000 });
        });
    };

    return (
        <Dialog header={`Share this file - ${fileName}`} visible={visible} onHide={onHide} style={{ width: '100%', maxWidth: '900px', padding: '20px' }}>
            <Toast ref={toast} />

            <div className="share-dialog-grid">
                {/* Left Side: Mobile Input and Send Button */}
                <div className="share-dialog-left">
                    <div className="share-dialog-input-row">
                        <InputText
                            id="mobile"
                            placeholder="Enter mobile number"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="share-dialog-input"
                            style={{width: '250px'}}
                        />
                        <Button label="Send" icon="pi pi-send" onClick={handleSend} className="share-dialog-small-button" disabled={loading} style={{width: '100px'}} />
                    </div>
                    <Divider align="center">
                        <span>OR</span>
                    </Divider>

                    <div className="share-dialog-link-row">
                        <Button label="Create Link" icon="pi pi-link" onClick={generateShareLink} className="share-dialog-link-button" disabled={loading} />
                    </div>
                    {showLink && (
                        <div className="share-dialog-generated-link">
                            <InputText value={shareLink} readOnly className="share-dialog-display-link" />
                            <Button icon="pi pi-copy" className="p-button-rounded p-button-text share-dialog-copy-button" onClick={copyToClipboard} />
                        </div>
                    )}
                </div>

                <div className="share-dialog-boundary"></div>

                {/* Right Side: Settings with Vertical Alignment */}
                <div className="share-dialog-right">
                    <div className="share-dialog-settings-title">
                        <h3>Settings</h3>
                    </div>

                    <div className="share-dialog-settings-group">
                        <div className="share-dialog-float-label">
                            <label htmlFor="linkType" style={{ marginRight: '11px' }}>Sharing Mode</label>
                            <Dropdown value={linkType} options={linkTypeOptions} onChange={(e) => setLinkType(e.value)} placeholder="Sharing Mode" />
                        </div>
                        <div className="share-dialog-float-label">
                            <label htmlFor="expiry" style={{ marginRight: '5px' }}>Set Link Expiry</label>
                            <Dropdown value={expiry} options={expiryOptions} onChange={(e) => setExpiry(e.value)} placeholder="Set Link Expiry" />
                        </div>
                        {linkType === 'with-password' && (
                            <div className="share-dialog-float-label" style={{ display: 'flex', alignItems: 'center' }}>

                                {existingPassword ? (<><label htmlFor="password" >
                                    Change Password
                                </label></>) : (<><label htmlFor="password" style={{ marginRight: '12px' }}>
                                    Set Password
                                </label></>)}
                                <InputText
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    className="p-inputtext"
                                    style={{ width: '200px' }}
                                />
                                <Button
                                    icon={isPasswordVisible ? "pi pi-eye-slash" : "pi pi-eye"}
                                    className="p-button-text p-button-plain"
                                    onClick={togglePasswordVisibility}
                                    aria-label="Show/Hide Password"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {loading && <ProgressSpinner />}
        </Dialog>
    );
};

export default ShareDialog;
