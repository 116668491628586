import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { TieredMenu } from 'primereact/tieredmenu';
import { ProgressSpinner } from 'primereact/progressspinner';
import Profile from './profileV2'
import { updateCategories } from '../utils/common';
import { API_URL } from '../config';

const VaultHeader = ({  handleCloseView, onUploadClick, onCategoryChange, onSearch, setUserLocal, 
    mobile, showSecondHeader, setFilteredDocs, documents,
     setShowOverwriteDialog, docsWithMissingNames }) => {
    const [userData, setUserData] = useState({});
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([ { label: 'All Category', value: 'all' }]);
    const [searchValue, setSearchValue] = useState('');
    const [recording, setRecording] = useState(false);
    const [displayUploadDialog, setDisplayUploadDialog] = useState(false);
    const [showSearchDialog, setShowSearchDialog] = useState(false);
    const [isDeepSearch, setIsDeepSearch] = useState(false);
    const [exitDeepSearch, setExitDeepSearch] = useState(false);
    const [deepsearchValue, setDeepSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const inputRef = useRef(null);
    const menu = useRef(null);
    const navigate = useNavigate();

    const userMenuItems = [
        { label: 'Profile', icon: 'pi pi-user', command: () => { navigate('/profile'); } },
        { label: 'Log out', icon: 'pi pi-sign-out', command: () => { setUserLocal(null); } },
    ];

    const StartVoiceSearch = () => {
        setRecording(true);
    };

    const StopVoiceSearch = () => {
        setRecording(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_URL}/auth/user/${mobile}`);
                const data = await response.json();
                setUserData(data);
                
                const userCategories = updateCategories(data.categories? data.categories: []);
                setCategories(userCategories);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    const onCategorySelect = (e) => {
        setExitDeepSearch(false);
        setCategory(e.value);
        onCategoryChange(e.value); // Pass selected category to parent
    };

    const onSearchInput = (e) => {
        console.log('search input pressed');
        setExitDeepSearch(false);
        setSearchValue(e.target.value);
        onSearch(e.target.value);
    };

    const onSearchInputV2 = (value) => {
        //setSearchValue(value);
        setDeepSearchValue(value);
    };

    const onUpload = () => {
        console.log("File(s) uploaded");
        setDisplayUploadDialog(false);
    };

    const handleSearchSubmit = async () => {
        setShowSearchDialog(false); // Close the search dialog
        setLoading(true); // Show loader
        try {
            const response = await fetch(`${API_URL}/vault/records/deepsearch?input=${deepsearchValue}&phone=${mobile}&category=${category}`);
            const data = await response.json();
            // Assuming the API returns a list of doc_ids
            setFilteredDocs(data);
            setLoading(false);
        } catch (error) {
            console.error("Error during deep search:", error);
        } finally {
            setLoading(false); // Hide loader after response
            setExitDeepSearch(true);
        }
    };

    const leftContents = (
        <React.Fragment>
            <Dropdown value={category} options={categories} placeholder="Filter Category" className="p-mr-2" style={{ minWidth: '100px' }} onChange={onCategorySelect} />
        </React.Fragment>
    );

    const centerContents = (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'relative', width: '95%', maxWidth: '400px' }}>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={searchValue}
                        onChange={onSearchInput}
                        ref={inputRef}
                        style={{ width: 'calc(100% - 34px)' }} // Leaves space for the mic icon
                    />
                </IconField>
            </div>
            <Button
                    label={ exitDeepSearch ? "Exit Deep Search Result": "Switch To Deep Search"}
                    className="p-button-text p-ml-2"
                    onClick={() => {
                        if(exitDeepSearch){
                            setFilteredDocs(documents);
                            setExitDeepSearch(!exitDeepSearch);
                        }else{
                            setIsDeepSearch(!isDeepSearch);
                            setShowSearchDialog(!showSearchDialog);
                        }
                    }}
                    style={{color: '#fff'}}
                />
            </div>
            
        </React.Fragment>
    );

    const rightContents = (
        <React.Fragment>
            <Button 
            icon="pi pi-pencil" 
            className="p-button-rounded p-button-text p-mr-2" 
            style={{ backgroundColor: '#007ad9', color: '#fff' }} 
            onClick={() => setShowOverwriteDialog(true)} // Add your edit functionality here
            />
            <Button icon="pi pi-upload" className="p-button-rounded p-button-text p-mr-2" onClick={onUploadClick} style={{ backgroundColor: '#007ad9', color: '#fff', fontWeight: 'bold', fontSize: '16px' }} />
            <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#007ad9', color: '#fff' }} onClick={(event) => menu.current.toggle(event)} />
            <TieredMenu model={userMenuItems} popup ref={menu} id="user_menu" />
        </React.Fragment>
    );
    const centerContents2 = (<React.Fragment>
        <Button 
                icon="pi pi-home" 
                className="p-button-text" 
                onClick={handleCloseView}  // Navigate to home
                style={{ color: '#fff', marginRight: '10px' }} 
        />
        <h2 style={{ margin: 0, cursor: 'pointer' }} onClick={handleCloseView} >Vault Dashboard</h2>
    </React.Fragment>);

    const topRightContents = (
        <React.Fragment>
            <Button label="About Us" className="p-button-link" style={{ color: '#fff' }} onClick={() => window.location.href = 'https://www.jeeva-ai.com/about-us'} />
            <Button label="Contact Us" className="p-button-link p-ml-2" style={{ color: '#fff' }} onClick={() => window.location.href = 'https://www.jeeva-ai.com/contact-us'} />
        </React.Fragment>
    );
    return (
        <div className="fixed-header">
            {showProfile ? (
                <Profile mobileNumber={mobile} />
            ) :
                (<><Toolbar className="p-justify-center" style={{ backgroundColor: '#007ad9', color: '#fff', padding: '5px 0', borderBottom: '1px solid #ddd' }}
                    start={centerContents2}
                    end={topRightContents}
                >

                </Toolbar>
                    {showSecondHeader && (<>
                        <Toolbar
                            style={{ backgroundColor: '#007ad9', padding: '2px 10px', borderBottom: '1px solid #ddd' }}
                            start={leftContents}
                            center={centerContents}
                            end={rightContents}
                        />
                    </>)}

                    <Dialog
                        header="Search"
                        visible={showSearchDialog}
                        onHide={() => setShowSearchDialog(false)} // Close search dialog
                        style={{ width: '60vw', maxWidth: '600px', height: 'auto' }}
                        breakpoints={{ '960px': '75vw', '640px': '90vw' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <textarea
                                placeholder="Type your query as sentence..."
                                value={deepsearchValue}
                                onChange={(e) => onSearchInputV2(e.target.value)}
                                style={{
                                    width: '100%',
                                    height: '100px',
                                    resize: 'none',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                }}
                            />
                            <Button
                                label="Submit"
                                className="p-button-text p-mt-2"
                                onClick={handleSearchSubmit} // Handle search on submit
                            />
                        </div>
                    </Dialog>

                    {/* Loader Dialog */}
                    <Dialog
                        header="Loading Results"
                        visible={loading}
                        closable={false} // Disable closing while loading, until Cancel button is clicked
                        style={{ width: '50vw', maxWidth: '400px', height: 'auto' }}
                        breakpoints={{ '960px': '75vw', '640px': '90vw' }}
                    >
                        <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100%' }}>
                            <ProgressSpinner />
                            <Button
                                label="Cancel"
                                className="p-button-text p-ml-4"
                                onClick={() => {
                                    setLoading(false); // Hide loader if user cancels
                                }}
                            />
                        </div>
                    </Dialog>

                </>)
            }


        </div>
    );
};

export default VaultHeader;
