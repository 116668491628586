import React, { useState, useRef, useEffect } from 'react';
import './Auth.css'; // Import the CSS file
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import LoginViaOtp from '../Components/LoginViaOtp';
import LoginViaPassword from '../Components/LoginViaPasssword';
import SignUp from '../Components/signUp';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_URL } from '../config';

const LoginPage = ({ setUserLocal }) => {
    const [loginMethod, setLoginMethod] = useState(null);
    const [mobile, setMobile] = useState('');
    const toast = useRef(null);
    const [phoneError, setPhoneError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const location = useLocation();
    
    const validatePhone = (phone) => {
        const phonePattern = /^[6-9]\d{9}$/; // Indian phone number validation
        return phonePattern.test(phone);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const mobileParam = queryParams.get('mobile');
        if (mobileParam && validatePhone(mobileParam.slice(2))) {
            setMobile(mobileParam.slice(2)); // Remove country code if you want
        }
    }, [location.search]);

    const handlePasswordMethod = (e) => {
        e.preventDefault();
        if (!validatePhone(mobile)) {
            setPhoneError("Mobile Number Invalid");
            return;
        } else {
            setPhoneError('');
        }
        setLoginMethod('password');
    };

   

    const sendOtp = async (e) => {
        e.preventDefault();
        if (!validatePhone(mobile)) {
            setPhoneError("Mobile Number Invalid");
            return;
        } else {
            setPhoneError('');
        }
        try {
            const response = await axios.post(`${API_URL}/auth/send-otp-v2`, { phone: "91" + mobile });
            console.log('sendingotp', response.data);
            if (response.data.success) {
                console.log('otp sent true');
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                setOtpSent(true);
                setLoginMethod('otp');
            } else {
                setOtpSent(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
           
        } catch (error) {
            setOtpSent(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to send OTP', life: 3000 });
        }
    };

    const signUpHandle = () => {
        setSignUp(true);
        setLoginMethod('none');
    };

    return (
        <div>

            <div className="login-page">

                {
                    !loginMethod && (
                        <>
                            <Toast ref={toast} />
                            <div className="welcome-text">Welcome To Jeeva AI</div>
                            <Card className="card">
                                {!loginMethod && (
                                    <>
                                        <h2>Log In</h2>
                                        <div className="p-fluid">
                                            <div className="p-field">
                                                
                                                <InputText
                                                    id="mobile"
                                                    value={mobile}
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    className="inputFullWidth"
                                                    placeholder='Mobile No:'
                                                    
                                                />
                                            </div>
                                            {phoneError && <small className="error-message">{phoneError}</small>}
                                            <Button label="Log In via Password" onClick={handlePasswordMethod} className="buttonFullWidth" />
                                            <Button label="Log In via OTP" onClick={sendOtp} className="buttonFullWidth" />
                                        </div>
                                        <p>or</p>
                                        <Button label="Sign up" onClick={signUpHandle} className="p-button-secondary buttonFullWidth" />
                                    </>
                                )}
                            </Card>
                        </>
                    )
                }
                {loginMethod === 'otp' && otpSent && <LoginViaOtp mobile={mobile} setUserLocal={setUserLocal} />}
                {loginMethod === 'password' && !phoneError && <LoginViaPassword mobile={mobile} setUserLocal={setUserLocal} />}
                {signUp && <SignUp setUserLocal={setUserLocal}/> }
            </div>
        </div>
    );
};

export default LoginPage;
