import React from 'react';
import './UploadStatusCard.scss';
import { ProgressSpinner } from 'primereact/progressspinner'; // Import PrimeReact spinner

const UploadStatusCard = ({ status, message, onClose }) => {
    return (
        <div className="upload-status-card">
            <div className={`upload-item ${status}`}>
                <span className="file-name">{message}</span>
                <span className="upload-status">
                    {status === 'success' ? '✔️ Success' : status === 'error' ? '❌ Error' : (
                        <ProgressSpinner style={{ width: '30px', height: '30px' }} /> // Add spinner
                    )}
                </span>
            </div>
            <button className="close-btn" onClick={onClose}>Close</button>
        </div>
    );
};

export default UploadStatusCard;
