import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';

// Set up the worker using a CDN
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const DocumentViewer = ({ s3Url }) => {
    const [loading, setLoading] = useState(true);
    const [contentType, setContentType] = useState('');
    const [isImage, setIsImage] = useState(false);
    const [isPdf, setIsPdf] = useState(false);

    // Clear any previous renders (image or PDF)
    const clearRender = () => {
        const pdfContainer = document.getElementById('pdf-container');
        const imgElement = document.getElementById('content-image');
        
        if (pdfContainer) pdfContainer.innerHTML = ''; // Clear previous PDF pages
        if (imgElement) imgElement.src = ''; // Clear previous image
    };

    // Function to render image files
    const renderImage = (url) => {
        const img = document.getElementById('content-image');
        img.src = url;
        img.style.display = 'block';
    };

    // Function to render PDF files
    const renderPdf = async (url) => {
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        const container = document.getElementById('pdf-container');
        container.style.display = 'block';
        const scaleFactor = 2.5; // Adjust scale factor for quality (lower for smaller screens)

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const viewport = page.getViewport({ scale: scaleFactor });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Responsive canvas to fit screen size
            canvas.style.width = '100%'; 
            canvas.style.height = 'auto'; 

            container.appendChild(canvas);

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext);
        }
    };

    useEffect(() => {
        // Clear previous renders when s3Url changes
        clearRender();
        setLoading(true);
        setIsImage(false);
        setIsPdf(false);

        // Fetch file to determine content type
        fetch(s3Url, { method: 'HEAD' })  // Use HEAD request to fetch metadata without downloading the file
            .then((response) => {
                const contentType = response.headers.get('Content-Type');
                setContentType(contentType);

                if (contentType.startsWith('image/')) {
                    setIsImage(true);
                    renderImage(s3Url); // Render image if it's an image type
                } else if (contentType === 'application/pdf') {
                    setIsPdf(true);
                    renderPdf(s3Url); // Render PDF using pdf.js if it's a PDF
                } else {
                    console.error('Unsupported content type:', contentType);
                }
            })
            .catch((error) => {
                console.error('Error fetching file:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [s3Url]);  // The effect will run again when the s3Url changes

    return (
        <div style={{ width: '95%', height: '100vh', overflow: 'auto', paddingLeft: '0px', boxSizing: 'border-box' }}>
            {loading && <p>Loading document...</p>}
            {!loading && isPdf && <div id="pdf-container" style={{ width: '100%', height: 'auto' }} />}
            {!loading && isImage && (
                <img 
                    id="content-image" 
                    alt="Document" 
                    style={{
                        width: '100%',
                        height: 'auto',
                        maxWidth: '100%',
                        display: 'block',
                        margin: '0 auto',
                    }} 
                />
            )}
            {!loading && !isPdf && !isImage && <p>Unsupported document format: {contentType}</p>}
        </div>
    );
};

export default DocumentViewer;
