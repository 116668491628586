import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { ProgressSpinner } from 'primereact/progressspinner';

const UploadDialog = ({ visible, onHide, onUploadComplete, onUploadStart,uid, setFiles }) => {
    
    const toast = React.useRef(null);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const onFileSelect =  async(e) => {
        if (e.files.some(file => file.size > 8388608)) { // 10 MB size limit
            toast.current.show({ severity: 'error', summary: 'File Too Large', detail: 'File size should not exceed 8 MB', life: 3000 });
            await delay(1000)
            return;
        }
        setFiles(e.files);
    };

    const handleUpload = async () => {

        onUploadStart();
        toast.current.show({ severity: 'success', summary: 'Upload Successful', detail: 'Processing Upload', life: 3000 });        
        onUploadComplete();
    };

    return (
        <Dialog header="Upload Reports" visible={visible} onHide={onHide} style={{ width: '100%', maxWidth: '400px' }}>
            <Toast ref={toast} />

            <div className="p-d-flex p-flex-column p-ai-center" style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <h4 className="p-text-center">Please Upload  Reports</h4>
                    <h5 className="p-text-center" style={{ color: '#666' }}>कृपया रिपोर्ट अपलोड करें।</h5>
                    <p className="p-text-center" style={{ color: '#f00', fontSize: '10px'  }}>Only PDF and image files (JPG, PNG) are supported.</p>
                    <div style={{ width: '100%' }}>
                        <FileUpload 
                            name="files[]" 
                            multiple 
                            customUpload 
                            auto 
                            maxFileSize={8388608} 
                            onSelect={onFileSelect}
                            accept="application/pdf, image/*"
                            chooseLabel="Select"
                            style={{ width: '100%' }}
                            emptyTemplate={<p className="p-m-0 p-text-center">Drag 'n' drop some files here, or click to UPLOAD FILE button to select files.</p>}
                            itemTemplate={(file, options) => (
                                <div className="p-fileupload-row">
                                    <div>{file.name}</div>
                                    <Button type="button" icon="pi pi-times" className="p-button-danger" onClick={() => options.onRemove(file)} />
                                </div>
                            )} 
                        />
                    </div>
                </div>

                <Button label="Submit" icon="pi pi-upload" onClick={handleUpload}  style={{ width: '150px', marginTop: '20px' }} />
            </div>
        </Dialog>
    );
};

export default UploadDialog;
