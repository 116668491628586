import CryptoJS from 'crypto-js';
const encryptionKey = 'e7f4f7e0b5d3a2f4c7e3b3e6f2d8a7e9b1f2c3e7a8d9f2b4c6d1a8e7f4b3c7d1';

// Encryption function
export const encrypt = (text) => {
    if(!text || text === 'null')return null;
    const iv = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.enc.Hex.parse(encryptionKey);

    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return `${iv.toString(CryptoJS.enc.Hex)}:${encrypted.toString()}`;
};

// Decryption function
export const decrypt = (text) => {
    if(!text || text === 'null')return null;
    const [ivHex, encryptedText] = text.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const key = CryptoJS.enc.Hex.parse(encryptionKey);

    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
};