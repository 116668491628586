import "./CardComponent.scss";
import { useRef } from "react";
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

const keyMap = {
    fileName : "File Name",
    author: 'Author',
    category: 'Category',
    name: 'Name',
    date_created: 'Created Date',
    sub_category:'Sub Category',
    department:'Department'
}

export const CardComponent = (props) => {
    const menu = useRef(null);
    
    return <><section class="product">
	<div class="product__photo">
		<div class="photo-container">
			<div class="photo-main">
				<div class="controls">
                    <Button
                        icon="pi pi-ellipsis-h"
                        className="p-button-rounded p-button-text"
                        onClick={(event) => menu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                        style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 2}}
                    />
                    <TieredMenu model={props.menuItems} popup ref={menu} id="popup_menu" />
				</div>
                
                <div class="image-container">  
				<img src="./Lock.png"></img>
                    <div class="image-button"><button onClick={ (event) => props.onMenuSelect('view')}> View Document </button></div>
                </div>
			</div>
			<div class="photo-album">
            <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text info-button" onClick={props.toggleInfo} />
            {props.showInfo && (
                    <div style={{ marginTop: '8px', color: '#333', fontSize: '12px' }}> {/* Adjusted font size and margin */}
                        {props.brief_summary}
                    </div>
                )}
			</div>
		</div>
	</div>
	<div class="product__info">

		<div class="title">
			<h1>{props.title}</h1>
		
		</div>
		<div class="description">
        {Object.entries(props.extraInfo).map(([key, value]) => (
                                <div key={key} class="description-section">
                                    <span class="description-section-key" style={{ color: '#ff0000' }}>{keyMap[key]}:</span>
                                    <span class="description-section-value"> {value}</span> 
                                </div>
                            ))}
		</div>
		<button class="buy--btn" onClick={(event) => {props.onMenuSelect('find')}}>Search in Document</button>
	</div>
</section>
</>

}