import { useEffect, useState } from "react";
import Vault from "./Pages/Main";
import SignIn from "./Pages/Auth";
import SignUp from "./Pages/Auth";
import ResetPassword from './Pages/Auth';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css"
import { encrypt, decrypt } from "./utils/crypto";
import Profile from "./Components/profileV2";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);  

  const setUserLocal = (user) => {
    setUser(user);
    localStorage.setItem('uid', encrypt(user));
  };

  useEffect(() => {
    const uid = decrypt(localStorage.getItem("uid"));
    setUser(uid);
    setLoading(false);  
  }, []);

  if (loading) {
    // You can return a spinner or a loading screen here
    return <div>Loading...</div>;
  }

  // Function to determine which route to render based on user existence
  const getInitialRoute = () => {
    if (user) {
      return <Navigate to="/" replace />;
    } else {
      return <Navigate to="/signin" replace />;
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Vault setUserLocal={setUserLocal} /> : <Navigate to="/signin" replace />} />
        <Route path="/signin" element={user ? <Navigate to="/" replace /> : <SignIn setUserLocal={setUserLocal} />} />
        <Route path="/signup" element={user ? <Navigate to="/" replace /> : <SignUp setUserLocal={setUserLocal} />} />
        <Route path="/reset-password" element={user ? <Navigate to="/" replace /> : <ResetPassword setUserLocal={setUserLocal} />} />
        <Route path="/profile" element={user ? <Profile setUserLocal={setUserLocal}  /> : <Navigate to="/signin" replace />} />
        <Route path="*" element={getInitialRoute()} />
      </Routes>
    </Router>
  );
};

export default App;
