const capitalizeFirsChar = (sentence) =>{
    return sentence
        .split(' ') // Split the sentence into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(' '); // Join the words back into a sentence
  }

export const updateCategories = ( userCategories) => {
    const categoriesData = [{ label: 'All Category', value: 'all' }];
    userCategories.forEach(element => {
        categoriesData.push({ label: capitalizeFirsChar(element + ' category'), value: element});
    });
    return categoriesData;
};