import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Auth.css'; // Import the same CSS file
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputOtp } from 'primereact/inputotp';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { API_URL } from '../config';

const LoginViaOtp = ({ mobile , setUserLocal}) => {
    const [otp, setOtp] = useState('');
    const [verificationFailed, setVerificationFailed] = useState(false);
    // const [otpVerified, setOtpVerified] = useState(false);
    // const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);

    const goBack = () => {
        navigate('/'); // This will navigate back to the previous page
        // Or navigate to a specific route like navigate('/login-method');
    };


    const verifyOtp = async() => {
        try {
            const response = await axios.post(`${API_URL}/auth/verify-otp-login-v2`, {
                phone: ("91"+mobile),
                otp: otp
            });      
            if (response.data.success) {
                //setOtpVerified(true);
                setVerificationFailed(false);
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'OTP verified successfully', life: 3000 });
                setUserLocal("91"+mobile);
                navigate('/');
            } else {
               // setOtpVerified(false);
                setVerificationFailed(true);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'OTP verification failed', life: 3000 });
            }
        } catch (error) {
           // setOtpVerified(false);
            setVerificationFailed(true);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to verify OTP', life: 3000 });
        }
    };

    const reSendOtp = async (e) => {
        try {
            const response = await axios.post(`${API_URL}/auth/send-otp-v2`, { phone: "91" + mobile });
            if (response.data.success) {
               // setOtpSent(true);
               setOtp('');
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
            } else {
              //  setOtpSent(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
            return;
        } catch (error) {
            console.log('start');
           console.log(error);
           console.log('end');
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to send OTP', life: 3000 });
            return;
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="welcome-text">Welcome To Jeeva AI</div>
            <Card className="card">
                <h2>Log In via OTP</h2>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="otp">OTP:</label>
                        <InputOtp
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.value)}
                            className="inputFullWidth"
                          
                        />
                    </div>
                    <Button label="Log In" onClick={verifyOtp} className="buttonFullWidth" />
                    {verificationFailed && (
                        // <Button label="Resend OTP" onClick={reSendOtp} className="buttonFullWidth p-button-secondary" />
                        <Button label="Resend Code" onClick={reSendOtp} link className="p-0"></Button>
                    )}
                    
                </div>
            </Card>
            <Button label="Back" onClick={goBack} severity="info" text />
            
        </div>
    );
};

export default LoginViaOtp;
