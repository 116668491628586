import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import './Sidebar.css';

const CustomSidebar = ({ documents, onDocumentSelect, onBack, title, visible , onHideSidebar, toggleSidebar}) => {
    return (
        <div className="custom-sidebar-container">
            <Sidebar visible={visible} onHide={onHideSidebar} className="custom-sidebar" position="left">
                <Button icon="pi pi-arrow-left" className="p-button-text" onClick={onBack} />
                <h3>{title}</h3>
                <ul className="document-list">
                    {documents.map(doc => (
                        <li key={doc._id} onClick={() => onDocumentSelect(doc)} className="document-title">
                            {doc?.tag?.general_tags?.title}
                        </li>
                    ))}
                </ul>
            </Sidebar>
        </div>
    );
};

export default CustomSidebar;
